<template>
    <div>
      <Carousel :itemsToShow="1">
        <Slide v-for="(slide, index) in slides" :key="index">
          <div class="carousel-slide">
            <img :src="slide.image" alt="Slide Image" />
            <p>{{ slide.text }}</p>
          </div>
        </Slide>
      </Carousel>
      <div>
        <h1>DonOLD j drumpf</h1>
        <p> He is a dementia patient, who has the vocabulary of a C grade 2nd grader.</p>
        <p> He is a racist, a rapist, a pedophile, a liar and has the morals of a pig.  No offense to actual pigs.  Everyone is saying it, you know it, I know it, everyone knows it</p>
        <p> Does he know where he is?  What year it is?  He thinks he is running againg President Obama and Hillary.</p>
        <p> He is a danger to himself and others.  He is a danger to the world.  He is a danger to the United States.</p>
        <p> He hates the military and doesn't understand what sacrifice or serving the country even means.  He serves only himself, and would throw his own children under the bus if it gets him out of trouble. </p>
        <p> His fortune was funneled illegaly in order to avoid paying taxes, and he would have 2-5 times as much money if he had invested in an index fund rather than play being a 'businessman' with dozens of failed ventures and bankruptcies.</p>
        <p> This is a child, who has had everything handed to him, never worked a day in his life, cheated on all 3 of his wives and wants to be a dictator.</p>
      </div>
    </div>
  </template>
  
  <script>
  import { Carousel, Slide } from 'vue3-carousel';
  import 'vue3-carousel/dist/carousel.css';
  
  export default {
    name: 'MainPage',
    components: {
      Carousel,
      Slide,
    },
    data() {
      return {
        slides: [
          { image: require('@/assets/LikeElvis.png'), text: "Not looking good donny." },
          { image: require('@/assets/oldfeeblerapist.png'), text: "Someones grandpa got out, he shidded and pissed in his diaper.  Can someone find his family?" },
          { image: require('@/assets/shiddedhisself.png'), text: "The big boy shidded in court." }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  .carousel-slide {
    text-align: center;
  }
  .carousel-slide img {
    max-width: 100%;
    height: auto;
  }
  .carousel-slide p {
    margin-top: 10px;
    font-size: 16px;
  }
  </style>